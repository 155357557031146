import React from "react";
import { SkillSet as SkillSetComponent } from "..";

import { Member, SkillSet } from "../../types";

interface SkillsTableProps {
  people: Member[];
  skillSets: SkillSet[];
}

const SkillsTable: React.FC<SkillsTableProps> = ({ people, skillSets }) => {
  return (
    <div>
      <div className="table-row">
        <div className="table-cell"></div>
        {people.map((p) => (
          <div key={`person-${p.name}`} className="table-cell text-center">
            {p.name}
          </div>
        ))}
      </div>
      {skillSets.map((s) => (
        <SkillSetComponent
          key={`skillSet-${s.name}`}
          users={people}
          skillSet={s}
        />
      ))}
    </div>
  );
};

export default SkillsTable;
