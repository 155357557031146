import React, { useEffect, useState } from "react";

import { SkillsTable } from "./components";
import useSocket from "./hooks/useSocket";
import { Member, SkillSet } from "./types";

function App() {
  const [skillSets, setSkillSets] = useState<SkillSet[]>([]);
  const [users, setUsers] = useState<Member[]>([]);
  const [connectState, setConnectedState] = useState(false);

  const socket = useSocket();
  const teamName = "yard";

  useEffect(() => {
    socket.addEventListener("message", console.log);
  }, [socket]);

  useEffect(() => {
    socket.addEventListener("message", (event) => {
      const data = JSON.parse(event.data);

      if (data.type === "team") {
        setSkillSets(data.payload.skillSets);
        setUsers(data.payload.members);
      }
    });
  }, [socket]);

  useEffect(() => {
    socket.addEventListener("open", () => {
      socket.send(JSON.stringify({ action: "team", teamName }));
    });
  }, [teamName, socket]);

  useEffect(() => {
    socket.addEventListener("open", () => setConnectedState(true));
    socket.addEventListener("close", () => setConnectedState(false));
  }, [socket]);

  return (
    <div className="container mx-auto">
      <h1 className="text-3xl font-bold underline">
        Musketeer: {connectState ? "Connected" : "Not connected"}
      </h1>
      <div>
        <SkillsTable people={users} skillSets={skillSets} />
      </div>
    </div>
  );
}

export default App;
