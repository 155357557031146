import { createContext, ReactChild, useEffect, useState } from "react";

const socketUrl = "wss://83i5gdmk0g.execute-api.eu-west-2.amazonaws.com/dev/";

const webSocket = new WebSocket(socketUrl);

export const SocketContext = createContext(webSocket);

interface SocketProviderProps {
  children: ReactChild;
}

const SocketProvider = (props: SocketProviderProps) => {
  const [ws, setWs] = useState<WebSocket>(webSocket);

  useEffect(() => {
    let timeout: number | undefined = undefined;
    const onClose = () => {
      // reconnect the socket
      console.log("onClos called");
      timeout = window.setTimeout(() => {
        console.log("Reconnecting web socket");
        setWs(new WebSocket(socketUrl));
      }, 1000);
    };

    ws.addEventListener("close", onClose);
    return () => {
      ws.removeEventListener("close", onClose);
      if (timeout) {
        window.clearTimeout(timeout);
      }
    };
  }, [ws, setWs]);

  return (
    <SocketContext.Provider value={ws}>{props.children}</SocketContext.Provider>
  );
};

export default SocketProvider;
