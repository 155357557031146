import React from "react";

interface Skill {
  name: string;
}

interface SkillSetProps {
  users: { name: string }[];
  skillSet: { name: string; skills: Skill[] };
}

const SkillSet: React.FC<SkillSetProps> = ({ users, skillSet }) => {
  // put this in a context from the currently active user

  return (
    <div className="table-row-group">
      <div>{skillSet.name}</div>

      {skillSet.skills.map((s) => (
        <div
          key={`skill-set-${s.name}`}
          className="table-row hover:bg-gray-100"
        >
          <div className="table-cell pl-4">{s.name}</div>

          {users.map((u) => {
            let inputClassNames = "shadow border rounded w-full";
            return (
              <div
                key={`skill-set-input-${s.name}-${u.name}`}
                className="table-cell p-2"
              >
                <input className={inputClassNames} type="text" />
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default SkillSet;
